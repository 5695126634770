import { LocationPage } from "@/pages/LocationPage/LocationPage";
import { LocationsPage } from "@/pages/LocationsPage/LocationsPage";
import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "../pages/HomePage/HomePage";
import { TestPage } from "../pages/TestPage/TestPage";

export enum APP_PATH {
  HOME = "/",
  TEST = "/test",
  LOCATION = "/location/:id",
  LOCATIONS_LIST = "/locations",
}

export const router = createBrowserRouter([
  {
    path: APP_PATH.HOME,
    element: <HomePage />,
    children: [
      {
        path: APP_PATH.LOCATION,
        element: <LocationPage />,
      },
      {
        path: APP_PATH.LOCATIONS_LIST,
        element: <LocationsPage />,
      },
    ],
  },
  {
    path: APP_PATH.TEST,
    element: <TestPage />,
  },
]);
