import { useAppSelector } from "@/core/store/store";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";

export const GlobalLoading = () => {
  const { loading } = useAppSelector((state) => state.ui);
  return (
    <>
      {loading && (
        <>
          <BlockUI blocked className="w-screen h-screen" />
          <div className="absolute flex align-items-center justify-content-center w-screen h-screen">
            <ProgressSpinner />
          </div>
        </>
      )}
    </>
  );
};
