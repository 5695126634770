import { IconCurrentLocation } from "@/assets/imgs/imgs";
import { DEFAULT_ZOOM } from "@/constants/map";
import { useAppSelector } from "@/core/store/store";
import { useMap } from "@vis.gl/react-google-maps";
import { Button } from "primereact/button";

export const MapControlBottomRight = () => {
  const map = useMap();

  const { location } = useAppSelector((state) => state.user);

  return (
    <div className="flex flex-column gap-2 m-2">
      {(!!location.latitude || !!location.longitude) && (
        <Button
          data-testid="current-location-button"
          icon={() => <IconCurrentLocation />}
          onClick={() => {
            if (!map) return;

            map.panTo({
              lat: location.latitude || 0,
              lng: location.longitude || 0,
            });
            map.setZoom(10);
          }}
          rounded
        />
      )}
      <Button
        icon={<i className="pi pi-plus" />}
        rounded
        onClick={() => {
          if (!map) return;

          map.setZoom((map.getZoom() || DEFAULT_ZOOM) + 1);
        }}
        data-testid="zoom-in-button"
      />
      <Button
        icon={<i className="pi pi-minus" />}
        rounded
        onClick={() => {
          if (!map) return;

          map.setZoom((map.getZoom() || DEFAULT_ZOOM) - 1);
        }}
        data-testid="zoom-out-button"
      />
    </div>
  );
};
