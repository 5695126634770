import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { i18nLanguages } from "@/i18n/i18n";
import { Avatar } from "primereact/avatar";
import type { Menu as MenuType } from "primereact/menu";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { useRef } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

export const LanguageSelector = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { language } = useAppSelector((state) => state.user);

  const menu = useRef<MenuType>(null);

  const onLanguageChange = (key: string) => {
    dispatch(userActions.setLanguage({ value: key, isManual: true }));
  };

  const items: MenuItem[] = Object.keys(i18nLanguages).map((key) => ({
    label: t(`language.${key}`),
    icon: <ReactCountryFlag className="mr-3" countryCode={i18nLanguages[key].code} svg />,
    command: () => onLanguageChange(key),
  }));

  return (
    <div>
      <Avatar
        className="cursor-pointer w-2rem h-2rem"
        shape="circle"
        size="large"
        onClick={(e) => menu.current?.toggle(e)}
        template={
          <ReactCountryFlag
            countryCode={i18nLanguages[language.value].code}
            svg
            className="w-full h-full object-cover"
            alt={`language-${language.value}`}
          />
        }
        data-testid="select-language-button"
      />
      <Menu model={items} popup ref={menu} />
    </div>
  );
};
