import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const TRANS_KEY = "components.Map.FiltersMap";

export const FiltersMap = () => {
  const { t } = useTranslation();

  const filtersRef = useRef<OverlayPanel>(null);

  return (
    <>
      <Button
        icon="pi pi-filter"
        rounded
        onClick={(e) => {
          filtersRef.current?.toggle(e);
        }}
      />

      <OverlayPanel ref={filtersRef}>
        <header className="flex justify-between mb-4">
          <h2 className="m-0 align-self-center">{t(`${TRANS_KEY}.title`)}</h2>
          <Button
            icon="pi pi-times"
            rounded
            text
            onClick={() => {
              filtersRef.current?.hide();
            }}
          />
        </header>
        <footer className="flex flex-wrap justify-content-center gap-2">
          <Button
            icon="pi pi-trash"
            label={t(`${TRANS_KEY}.clear`)}
            severity="secondary"
            rounded
            onClick={() => {
              filtersRef.current?.hide();
            }}
          />
          <Button
            icon="pi pi-filter"
            label={t(`${TRANS_KEY}.action`)}
            rounded
            onClick={() => {
              filtersRef.current?.hide();
            }}
          />
        </footer>
      </OverlayPanel>
    </>
  );
};
