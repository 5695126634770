import { useAppSelector } from "@/core/store/store";
import { useGeoLocation } from "@/hooks/useGeoLocation";
import { useTheme } from "@/hooks/useTheme";
import i18n from "@/i18n/i18n";
import { locale } from "primereact/api";
import { createContext, ReactNode, useEffect, useState } from "react";
import { version } from "../../package.json";

const AppContext = createContext({});

interface AppProviderProps {
  children: ReactNode;
}
export const AppProvider = ({ children }: AppProviderProps) => {
  useGeoLocation();

  const { isManualTheme, theme, setTheme } = useTheme();

  const { language } = useAppSelector((state) => state.user);

  const [loaders, setLoaders] = useState({
    theme: false,
  });

  useEffect(() => {
    // Hide loader after 15 seconds
    setTimeout(() => {
      setLoaders({
        theme: true,
      });
    }, 15 * 1000);
  }, []);

  // Load theme
  useEffect(() => {
    const navigatorTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";

    const newTheme = isManualTheme ? theme : navigatorTheme;
    setTheme(newTheme);

    setLoaders((prev) => ({ ...prev, theme: true }));
  }, [isManualTheme]); // eslint-disable-line

  useEffect(() => {
    const newLanguage = language.isManual ? language.value : i18n.language;
    i18n.changeLanguage(newLanguage);
    locale(newLanguage);
  }, [language]);

  // Add version to meta tag
  useEffect(() => {
    const metaTag = document.querySelector('meta[name="version"]');
    if (metaTag) {
      metaTag.setAttribute("content", version);
    }
  }, []);
  return <AppContext.Provider value={{}}>{loaders.theme && children}</AppContext.Provider>;
};
