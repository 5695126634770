import { SearchButton } from "@/components/buttons/SearchButton/SearchButton";
import { FiltersMap } from "@/components/Map/FiltersMap/FiltersMap";
import { APP_PATH } from "@/router/routes";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";

export const MapControlTopRight = () => {
  const navigate = useNavigate();
  const locationPath = useLocation();

  return (
    <div className="flex gap-2 m-2">
      <SearchButton />
      <FiltersMap />
      <Button
        icon="pi pi-list"
        rounded
        onClick={() => {
          const path = locationPath.pathname === APP_PATH.LOCATIONS_LIST ? APP_PATH.HOME : APP_PATH.LOCATIONS_LIST;
          navigate(path);
        }}
        data-testid="toggle-locations-list-button"
      />
    </div>
  );
};
