import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ThemeApp = "light" | "dark";
export interface UserSlice {
  location: {
    latitude: number | null;
    longitude: number | null;
  };
  theme: ThemeApp;
  isManualTheme: boolean;
  language: {
    value: string;
    isManual: boolean;
  };
  test: string;
}

export const userSliceInitialState: UserSlice = {
  location: {
    latitude: null,
    longitude: null,
  },

  theme: "light",
  isManualTheme: false,
  language: {
    value: "es",
    isManual: false,
  },
  test: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: userSliceInitialState,
  reducers: {
    setlocation: (state, { payload }: PayloadAction<UserSlice["location"]>) => {
      state.location = payload;
    },
    setTheme: (state, { payload }: PayloadAction<UserSlice["theme"]>) => {
      state.theme = payload;
    },
    setIsManualTheme: (state, { payload }: PayloadAction<UserSlice["isManualTheme"]>) => {
      state.isManualTheme = payload;
    },
    setLanguage: (state, { payload }: PayloadAction<UserSlice["language"]>) => {
      state.language = payload;
    },
    setTest: (state, { payload }: PayloadAction<UserSlice["test"]>) => {
      state.test = payload;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
