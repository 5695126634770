import en from "./locales/en.json";
import es from "./locales/es.json";

import locale_en from "primelocale/en.json";
import locale_es from "primelocale/es.json";
import { addLocale } from "primereact/api";

import i18n from "i18next";
import LanguageDetector, { DetectorOptions } from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const options: DetectorOptions = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
  convertDetectedLanguage: (lng) => lng.split("-")[0],
};

export const i18nLanguages: Record<string, any> = {
  en: {
    translation: en,
    code: "GB",
  },
  es: {
    translation: es,
    code: "ES",
  },
};

// Add locales to PrimeReact
addLocale("en", locale_en.en);
addLocale("es", locale_es.es);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    detection: options,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: i18nLanguages,
  });

export default i18n;
