import { ClusterLocation } from "@/core/models/LocationModel";

export type LocationMarkerProps = {
  cluster: ClusterLocation;
};
export const LocationMarker = ({ cluster }: LocationMarkerProps) => {
  if (!cluster.properties) return null;

  const { statusId, socketNumbers } = cluster.properties;

  const statusColor: Record<number, string> = {
    0: "green-500",
    1: "cyan-500",
    2: "gray-500",
  };

  return (
    <div className="flex justify-center items-center">
      <div
        className={`w-3rem h-3rem bg-transparent rounded-full border-${statusColor[statusId]} flex justify-center items-center`}
        style={{ border: "4px solid" }}
      >
        <div
          className={`w-2rem h-2rem bg-${statusColor[statusId]} rounded-full flex justify-center items-center text-white`}
        >
          {socketNumbers}
        </div>
      </div>
    </div>
  );
};
