import { AppHeader } from "@/components/AppHeader/AppHeader";

export const TestPage = () => {
  return (
    <div>
      <AppHeader />
      <h1 className="ml-5">TestPage</h1>
    </div>
  );
};
