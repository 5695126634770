import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { PrimeReactContext } from "primereact/api";
import { useContext } from "react";

export const useTheme = () => {
  const { changeTheme } = useContext(PrimeReactContext);
  const dispatch = useAppDispatch();

  const { theme, isManualTheme } = useAppSelector((state) => state.user);

  const setTheme = (newTheme: "dark" | "light") => {
    const oldTheme = newTheme === "dark" ? "light" : "dark";
    changeTheme?.(`lara-${oldTheme}-purple`, `lara-${newTheme}-purple`, "theme-link");
    dispatch(userActions.setTheme(newTheme));
  };

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    dispatch(userActions.setIsManualTheme(true));
    setTheme(newTheme);
  };

  return { theme, isManualTheme, setTheme, toggleTheme };
};
