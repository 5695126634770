import { LogoApp, LogoDarkApp } from "@/assets/imgs/imgs";
import { useTheme } from "@/hooks/useTheme";
import { APP_PATH } from "@/router/routes";
import { Menubar } from "primereact/menubar";
import { useNavigate } from "react-router-dom";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";
import { ToggleThemeButton } from "../buttons/ToggleThemeButton/ToggleThemeButton";

export const AppHeader = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();

  return (
    <Menubar
      data-testid="menubar"
      className="border-noround"
      start={
        <span data-testid="logo" onClick={() => navigate(APP_PATH.HOME)}>
          {theme === "light" ? (
            <LogoApp className="w-8rem cursor-pointer" />
          ) : (
            <LogoDarkApp className="w-8rem cursor-pointer" />
          )}
        </span>
      }
      end={
        <div className="flex align-items-center gap-4">
          <ToggleThemeButton />
          <LanguageSelector />
        </div>
      }
      style={{ height: "60px" }}
      pt={{
        button: {
          style: {
            display: "none",
          },
        },
      }}
    />
  );
};
