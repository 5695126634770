import { AppHeader } from "@/components/AppHeader/AppHeader";
import { MapClusters } from "@/components/Map/ClusterMap/MapClusters";
import { CurrentLocationMarker } from "@/components/Map/CurrentLocationMarker/CurrentLocationMarker";
import { DEFAULT_ZOOM, MADRID_CENTER } from "@/constants/map";
import { useAppSelector } from "@/core/store/store";
import { useSupercluster } from "@/hooks/useSuperCluster";
import { useTheme } from "@/hooks/useTheme";
import { APP_PATH } from "@/router/routes";
import { Map, useMap } from "@vis.gl/react-google-maps";
import * as GeoJSON from "geojson";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { locations } from "./locations.json";
import { MapControls } from "./MapControls/MapControls";

export const HomePage = () => {
  const { theme } = useTheme();
  const map = useMap();
  const locationPath = useLocation();

  const { clusterer } = useSupercluster(GeoJSON.default.parse(locations, { Point: ["lat", "lng"] }), {
    extent: 256,
    radius: 80,
    maxZoom: 12,
  });

  const { location } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!map) return;

    if (location.latitude || location.longitude) {
      map.panTo({
        lat: location.latitude || MADRID_CENTER.lat,
        lng: location.longitude || MADRID_CENTER.lng,
      });
      map.setZoom(10);
    }
  }, [map, location]);

  return (
    <div data-testid="home-page">
      <AppHeader />
      <Map
        mapId="9bc6ba3e7e5b7a89"
        defaultCenter={MADRID_CENTER}
        defaultZoom={DEFAULT_ZOOM}
        gestureHandling="greedy"
        disableDefaultUI={true}
        colorScheme={theme.toUpperCase()}
        style={{
          height: "calc(100vh - 60px)",
          width: `${locationPath.pathname === APP_PATH.LOCATIONS_LIST ? 75 : 100}vw`,
          transition: "width 0.5s ease",
        }}
        reuseMaps
        minZoom={6}
        // onIdle={(event) => {
        //   const newLimit = event.map?.getBounds()?.toJSON();
        //   console.log(newLimit);
        // }}
      >
        <MapClusters clusterer={clusterer} />
        <MapControls />
        <CurrentLocationMarker />
      </Map>
      <Outlet />
    </div>
  );
};
