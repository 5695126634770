import { ClusterLocationProperties } from "@/core/models/LocationModel";
import { FeatureCollection, Point } from "geojson";
import { useEffect, useMemo } from "react";
import Supercluster, { ClusterProperties, Options } from "supercluster";

export function useSupercluster(
  geojson: FeatureCollection<Point, ClusterLocationProperties>,
  superclusterOptions: Options<ClusterLocationProperties, ClusterProperties>,
) {
  const clusterer = useMemo(() => {
    return new Supercluster<ClusterLocationProperties, ClusterLocationProperties>({
      ...superclusterOptions,
      map: (props) => {
        return {
          ...props,
          socketNumbers: props?.socketNumbers || 0,
        };
      },
      reduce: (accumulated, props) => {
        accumulated.socketNumbers += props.socketNumbers || 0;
      },
    });
  }, [superclusterOptions]);

  useEffect(() => {
    clusterer.load(geojson.features);
  }, [clusterer, geojson]);

  return {
    clusterer,
  };
}
