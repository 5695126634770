import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface UiSlice {
  loading: boolean;
}

export const uiSliceInitialState: UiSlice = {
  loading: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: uiSliceInitialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<UiSlice["loading"]>) => {
      state.loading = payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
