import { LoadingBar } from "@/assets/imgs/imgs";
import { ControlPosition, MapControl } from "@vis.gl/react-google-maps";
import { useState } from "react";
import { MapControlBottomRight } from "./MapControlBottomRight/MapControlBottomRight";
import { MapControlTopRight } from "./MapControlTopRight/MapControlTopRight";

export const MapControls = () => {
  const [isLoadingLocations] = useState(false);

  return (
    <>
      <MapControl position={ControlPosition.TOP_RIGHT}>
        <MapControlTopRight />
      </MapControl>

      {/* Loader */}
      <MapControl position={ControlPosition.BOTTOM_CENTER}>
        {isLoadingLocations && (
          <div className="flex m-2 p-2 rounded-full surface-0 ">
            <LoadingBar />
          </div>
        )}
      </MapControl>

      {/* Map controls */}
      <MapControl position={ControlPosition.INLINE_END_BLOCK_END}>
        <MapControlBottomRight />
      </MapControl>
    </>
  );
};
