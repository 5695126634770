import i18n from "@/i18n/i18n";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { AutoComplete } from "primereact/autocomplete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TRANS_KEY = "components.SearchButton";

const pt = {
  panel: {
    style: { marginLeft: "10px", maxWidth: "9w" },
  },
  listwrapper: {
    style: { maxHeight: "500px" },
  },
};

export const SearchButton = () => {
  const { t } = useTranslation();
  const map = useMap();
  const places = useMapsLibrary("places");

  const [value, setValue] = useState<string>("");

  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const [predictionResults, setPredictionResults] = useState<google.maps.places.AutocompletePrediction[]>([]);

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);

  const handleSearch = async (event: any) => {
    const value = (event.query || "").trim();

    if (!autocompleteService || !value) {
      setPredictionResults([]);
      return;
    }

    try {
      const { predictions } = await autocompleteService.getPlacePredictions({
        input: value,
        sessionToken,
        language: i18n.language,
      });
      setPredictionResults(predictions);
    } catch (error) {
      console.error(error);
      setPredictionResults([]);
    }
  };

  const handleSuggestionClick = (placeId: string) => {
    if (!places) return;

    const detailRequestOptions: google.maps.places.PlaceDetailsRequest = {
      placeId,
      fields: ["geometry", "name", "formatted_address"],
      sessionToken,
    };

    const detailsRequestCallback = (placeDetails: google.maps.places.PlaceResult | null) => {
      const location = placeDetails?.geometry?.location;
      if (!location || !map) return;

      map.panTo(location.toJSON());
      map.setZoom(13);

      setPredictionResults([]);
      setSessionToken(new places.AutocompleteSessionToken());
    };

    placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
  };

  return (
    <div className="p-inputgroup flex-1">
      <AutoComplete
        field="name"
        value={value}
        suggestions={predictionResults}
        completeMethod={handleSearch}
        placeholder={t(`${TRANS_KEY}.placeholder`)}
        onChange={(e) => {
          const newValue = e.value;
          setValue(newValue?.description || newValue);

          if (newValue?.place_id) {
            handleSuggestionClick(newValue.place_id);
          }
        }}
        itemTemplate={(item: google.maps.places.AutocompletePrediction) => (
          <div>
            <div className="text-lg font-bold">{item.structured_formatting.main_text}</div>
            <div>{item.structured_formatting.secondary_text}</div>
          </div>
        )}
        pt={pt}
      />
      <span className="p-inputgroup-addon bg-primary-500 text-white">
        <i className="pi pi-search" />
      </span>
    </div>
  );
};
