import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch } from "@/core/store/store";
import { useEffect } from "react";

export const useGeoLocation = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) =>
        dispatch(
          userActions.setlocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
        ),
      (err) => {
        console.error("err", err);
        dispatch(userActions.setlocation({ latitude: null, longitude: null }));
      },
    );
  }, [dispatch]);

  return null;
};
