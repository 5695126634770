// PrimeReact
import "primeflex/primeflex.css"; // flex
import "primeicons/primeicons.css"; //icons
import { APIOptions, PrimeReactProvider } from "primereact/api";
import "primereact/resources/primereact.min.css"; //core css

import { GlobalLoading } from "@/components/GlobalLoading/GlobalLoading";
import { persistor, store } from "@/core/store/store";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { AppProvider } from "./context/AppContext";
import { Router } from "./router/Router";

const { VITE_GOOGLE_MAPS_API_KEY } = import.meta.env;

export const App = () => {
  const configPrimeReact: Partial<APIOptions> = {
    ripple: true,
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PrimeReactProvider value={configPrimeReact}>
          <AppProvider>
            <APIProvider apiKey={VITE_GOOGLE_MAPS_API_KEY} region="ES">
              <GlobalLoading />
              <Router />
            </APIProvider>
          </AppProvider>
        </PrimeReactProvider>
      </PersistGate>
    </Provider>
  );
};
