import { APP_PATH } from "./routes";

export const buildPath = (path: APP_PATH, params: Record<string, string | number>): string => {
  let builtPath: string = path;

  for (const [key, value] of Object.entries(params)) {
    builtPath = builtPath.replace(`:${key}`, value.toString());
  }

  return builtPath;
};
