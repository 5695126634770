import { useTheme } from "@/hooks/useTheme";
import { Button } from "primereact/button";

export const ToggleThemeButton = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <Button
      className="w-2rem h-2rem"
      onClick={toggleTheme}
      icon={<i className={`pi pi-${theme === "light" ? "moon" : "sun"}`} data-testid="toggle-theme-icon" />}
      severity="secondary"
      rounded
      data-testid="toggle-theme-button"
    />
  );
};
